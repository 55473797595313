export const ROLE_USER = "USER";
export const ROLE_AGENT = "AGENT";
export const ROLE_VIEWER = "VIEWER";
export const ROLE_MAKER = "MAKER";
export const ROLE_CHECKER = "CHECKER";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_ROOT = "ROOT";

export const USER_ROLES = [
  //ROLE_USER,
  //ROLE_AGENT,
  ROLE_VIEWER,
  ROLE_MAKER,
  ROLE_CHECKER,
  ROLE_ADMIN,
  ROLE_ROOT,
];
